<template>
  <div class="d-flex flex-row justify-center align-center">
    <div class="circle-indicator" :class="[isOn ? 'success' : 'red lighten-1']"></div>
  </div>
</template>

<script>
export default {
  name: 'CircleIndicator',

  props: {
    isOn: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
}
</script>

<style scoped>
.circle-indicator {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}
</style>
